<template>
<section class="py-5 w-screen overflow-hidden" id="menu">
  <div class="container mx-auto">
    <ScrollDetector @enter-client-rect="enter1=true">
      <div class="transition-all duration-1000 transform" :class="enter1? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
        <h6 class="din text-3xl"><span class=" inline-block border-b-2 pb-2 border-black">2</span></h6>
      </div>
    </ScrollDetector>
    <ScrollDetector @enter-client-rect="enter2=true">
      <div class="transition-all duration-1000 transform" :class="enter2? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
        <h3 class="din text-6xl tracking-wide mt-6">MENU</h3>
        <div class=" flex justify-between">
          <h4 class="text-sm font-bold mt-3 mb-8">くろくろのこだわりのメニュー</h4>
          <div>
            <button type="button" class=" text-gray-500 text-xl py-2 px-3 rounded hover:bg-gray-200 transition-colors" @click="$refs.MenuCarousel.goToPrev()">&lt;</button>
            <button type="button" class=" text-gray-500 text-xl py-2 px-3 rounded hover:bg-gray-200 transition-colors ml-4" @click="$refs.MenuCarousel.goToNext()">&gt;</button>
          </div>
        </div>
      </div>
    </ScrollDetector>
    <ScrollDetector @enter-client-rect="enter3=true">
      <div class="transition-all duration-1000 transform" :class="enter3? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
        <VueAgile ref="MenuCarousel" id="menu-pc" :slidesToShow="1.3" :infinite="false" :navButtons="false">
          <div v-for="i of 4" :key="i" class="slide w-96 pr-6">
            <img :src="imgSrc(i)" class=" w-full h-auto">
            <p class=" font-bold text-lg mt-3">{{ menuTitle[i-1] }}</p>
          </div>
          <div class="slide w-96 pr-6">
          </div>
        </VueAgile>
      </div>
    </ScrollDetector>
    <ScrollDetector @enter-client-rect="enter4=true">
      <div class="transition-all duration-1000 transform" :class="enter4? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
        <a href="#" class=" hidden block- bg-white border-2 border-black font-bold mt-7 text-center text-xl py-4 hover:bg-gray-100 transition-colors">
          全てのメニューを見る
        </a>
      </div>
    </ScrollDetector>
  </div>
</section>
</template>

<script>
import { VueAgile } from 'vue-agile'
import ScrollDetector from "vue-scroll-detector"

export default {
  components: {
    VueAgile, ScrollDetector
  },
  data() {
    return {
      enter1: false,
      enter2: false,
      enter3: false,
      enter4: false,
      menuTitle: ['ビーフシチュー', 'ハンバーグステーキ', 'シーフードグラタン', '手作りチーズケーキ', ],
    }
  },
  methods: {
    imgSrc(index) {
      if (window.innerWidth < 576) {
        return '/images/menu/' + index + '.jpg'
      } else if (window.devicePixelRatio > 1.0) {
        return '/images/menu/' + index + '@2x.jpg'
      }
      else return '/images/menu/' + index + '.jpg'
    }
  }
}
</script>

<style lang="scss">
#menu-pc {
  .agile__list {
    overflow: visible;
  }
}
</style>