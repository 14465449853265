<template>
<section class="mt-10" id="menu">
  <div class="container mx-auto">
    <ScrollDetector @enter-client-rect="enter1=true">
      <div class="transition-all duration-1000 transform" :class="enter1? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
        <h6 class="din text-2xl text-center"><span class=" inline-block border-b-2 pb-2 border-black">2</span></h6>
      </div>
    </ScrollDetector>
    <ScrollDetector @enter-client-rect="enter2=true">
      <div class="transition-all duration-1000 transform" :class="enter2? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
        <h3 class="din text-4xl text-center tracking-wide mt-6">MENU</h3>
        <h4 class="text-sm text-center font-bold mt-3 mb-14">くろくろのこだわりのメニュー</h4>
      </div>
    </ScrollDetector>
  </div><!-- /.container -->
  <div class="relative z-10">
    <button type="button" class=" absolute top-28 left-0 bg-white text-gray-800 text-sm py-2 px-3 hover:bg-gray-200 transition-colors" @click="$refs.MenuCarousel.goToPrev()">&lt;</button>
    <button type="button" class=" absolute top-28 right-0 bg-white text-gray-800 text-sm py-2 px-3 hover:bg-gray-200 transition-colors" @click="$refs.MenuCarousel.goToNext()">&gt;</button>
  </div>
  <ScrollDetector @enter-client-rect="enter3=true">
    <div class="transition-all duration-1000 transform" :class="enter3? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
      <VueAgile id="MenuCarousel" ref="MenuCarousel" :dots="true" :slidesToShow="1" :navButtons="false">
        <div v-for="i of 4" :key="i" class="slide w-96">
          <img :src="imgSrc(i)" class=" w-full h-auto">
          <p class=" font-bold text-lg px-6 mt-3">{{ menuTitle[i-1] }}</p>
        </div>
      </VueAgile>
    </div>
  </ScrollDetector>
  <ScrollDetector @enter-client-rect="enter4=true">
    <div class="container mx-auto px-6 transition-all duration-1000 transform" :class="enter4? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
      <a href="#" class=" hidden block- bg-white border-2 border-black font-bold mt-7 text-center py-4 hover:bg-gray-100 transition-colors">
        全てのメニューを見る
      </a>
    </div>
  </ScrollDetector>
</section>
</template>

<script>
import { VueAgile } from 'vue-agile'
import ScrollDetector from "vue-scroll-detector"

export default {
  components: {
    VueAgile, ScrollDetector
  },
  data() {
    return {
      enter1: false,
      enter2: false,
      enter3: false,
      enter4: false,
      menuTitle: ['ビーフシチュー', 'ハンバーグステーキ', 'シーフードグラタン', '手作りチーズケーキ', ],
    }
  },
  methods: {
    imgSrc(index) {
      if (window.innerWidth < 576) {
        return '/images/menu/' + index + '.jpg'
      } else if (window.devicePixelRatio > 1.0) {
        return '/images/menu/' + index + '@2x.jpg'
      }
      else return '/images/menu/' + index + '.jpg'
    }
  }

}
</script>

<style lang="scss">
#MenuCarousel {
  .agile__slides {
    align-items: flex-start;
  }
  .agile__actions {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
  }
  .agile__dots {
    button {
      background-color: #bbb;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 10px;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      transition-duration: .3s;
      width: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .agile__dot--current {
      button {
        background-color: #555; 
      }
    }
  }
}

</style>