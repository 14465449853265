<template>
<div class="">
  <VueAgile :fade="true" :infinite="true" :autoplay="true" :autoplaySpeed="4000" :speed="1000" :pauseOnHover="false" :navButtons="false">
    <div v-for="i of 3" :key="i" class="slide">
      <img :src="imgSrc(i)" class=" object-cover w-screen h-[calc(100vh-200px)] lg:h-screen">
    </div>
  </VueAgile>
</div>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
  name: 'HelloWorld',
  components: {
    VueAgile
  },
  props: {
    msg: String
  },
  methods: {
    imgSrc(index) {
      if (window.innerWidth < 576) {
        return '/images/slider-fw/' + index + '@xs.jpg'
      } else if (window.devicePixelRatio > 1.0) {
        return '/images/slider-fw/' + index + '@2x.jpg'
      }
      else return '/images/slider-fw/' + index + '.jpg'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<style lang="scss">
.h-\[calc\(100vh-200px\)\] {
  height: calc(100vh - 200px);
}
</style>