<template>
<section>
  <div class="container mx-auto">
    <ScrollDetector @enter-client-rect="enter1=true">
      <div class="transition-all duration-1000 transform" :class="enter1? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
        <h6 class="din text-2xl text-center"><span class=" inline-block border-b-2 pb-2 border-black">1</span></h6>
      </div>
    </ScrollDetector>
    <ScrollDetector @enter-client-rect="enter2=true">
      <div class="transition-all duration-1000 transform" :class="enter2? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
        <h3 class="din text-4xl text-center tracking-wide mt-6">CONCEPT</h3>
        <h4 class="text-sm text-center font-bold mt-3 mb-14">くろくろが大切にする3つのこと</h4>
      </div>
    </ScrollDetector>
  </div><!-- /.container -->
  <div class="relative z-10">
    <button type="button" class=" absolute top-28 left-0 bg-white text-gray-800 text-sm py-2 px-3 hover:bg-gray-200 transition-colors" @click="$refs.ConceptCarousel.goToPrev()">&lt;</button>
    <button type="button" class=" absolute top-28 right-0 bg-white text-gray-800 text-sm py-2 px-3 hover:bg-gray-200 transition-colors" @click="$refs.ConceptCarousel.goToNext()">&gt;</button>
  </div>
  <ScrollDetector @enter-client-rect="enter3=true">
    <div class="transition-all duration-1000 transform" :class="enter3? 'opacity-100 translate-y-0': 'opacity-0 translate-y-32'">
      <VueAgile id="ConceptCarousel" ref="ConceptCarousel" :dots="true" :slidesToShow="1" :navButtons="false">
        <div class="slide w-full">
          <img :src="'/images/concept/1.jpg'" class=" w-full h-auto">
          <div class="px-6 -mt-6">
            <h5 class="tsukushi text-3xl whitespace-nowrap">
              <span class=" inline-block bg-white px-2 pt-2">次の50年も</span><br>
              <span class=" inline-block bg-white py-2 pl-2">変わらない味を。</span>
            </h5>
            <p class="tsukushi regular text-sm leading-7 mt-4 text-justify">
              創業したのは、1972年。創業当時から変わらないこと。それは、『ずっと食べたい料理、長く食べたいものは、なんだろうと考え続ける』こと。『自分たちがおいしいと自信を持って言える料理をお客さんに食べてもらう』こと。そんな想いもあってか、ありがたいことに創業から50年変わらないオムライス、ハンバーグ、グラタン、クリームコロッケたちは今も愛され続けています。でも、くろくろの味の一番のファンは私たちかもしれません。これからもくろくろの味をきちんと届けていきます。
            </p>
          </div>
        </div>
        <div class="slide w-full">
          <img :src="'/images/concept/2.jpg'" class=" w-full h-auto">
          <div class="px-6 -mt-6">
            <h5 class="tsukushi text-3xl whitespace-nowrap">
              <span class=" inline-block bg-white px-2 py-2">くろくろの</span><br>
              <span class=" inline-block bg-white pl-2 pb-2">当たり前。</span>
            </h5>
            <p class="tsukushi regular text-sm leading-7 mt-4 text-justify">
              食材は近くの市場で、目利きする。お米とお野菜はもちろん無農薬。50年変わらない味、料理の肝になるホワイトソース、デミグラスソースをはじめ、野菜を惹き立てるドレッシングだって、全ていちから手作りです。「なんでわざわざ？」と聞かれることもありますが、くろくろにとったら当たり前。時間をかけて手間暇かけて作るからこそ、出会える味を、喜んでもらえる味を、知っているからです。
            </p>
          </div>
        </div>
        <div class="slide w-full">
          <img :src="'/images/concept/3.jpg'" class=" w-full h-auto">
          <div class="px-6 -mt-6">
            <h5 class="tsukushi text-3xl whitespace-nowrap">
              <span class=" inline-block bg-white px-2 pt-2">お客さんからの</span><br>
              <span class=" inline-block bg-white pl-2 py-2">嬉しい言葉。</span>
            </h5>
            <p class="tsukushi regular text-sm leading-7 mt-4 text-justify">
              地域から遠方から、お客さんが毎日訪れてくれます。初めてくろくろの料理を食べたお客さんからの「おいしかった」の一言。販売中の特製のスープを２年間、毎週買ってくださるお客さんからの「こんな食べ方したよ」という声。約25年間、毎日来てくれるおっちゃんの「ぼけたらあかんから、くろくろ行きやって家族に言われるねん」という話。どれも嬉しい言葉です。常連さんも、初めましてのお客さんにも変わらず、私たちは丁寧な料理、丁寧な接客でお待ちしています。くろくろでお会いできるのを楽しみにしています。
            </p>
          </div>
        </div>
      </VueAgile>
    </div>
  </ScrollDetector>
</section>
</template>

<script>
import { VueAgile } from 'vue-agile'
import ScrollDetector from "vue-scroll-detector"

export default {
  components: {
    VueAgile, ScrollDetector
  },
  data() {
    return {
      enter1: false,
      enter2: false,
      enter3: false,
    }
  }
 
}
</script>

<style lang="scss">
#ConceptCarousel {
  .agile__slides {
    align-items: flex-start;
  }
  .agile__actions {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
  }
  .agile__dots {
    button {
      background-color: #bbb;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 10px;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      transition-duration: .3s;
      width: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .agile__dot--current {
      button {
        background-color: #555; 
      }
    }
  }
}

</style>