<template>
<div class="relative z-20">
  <div class="fixed z-20 top-5 md:top-auto right-5 md:right-auto md:bottom-7 md:left-1/2">
    <div 
      class="tham tham-e-squeeze tham-w-8" 
      :class="{ 'tham-active': isGlobalMenuOpen }"
      @click="isGlobalMenuOpen = !isGlobalMenuOpen"
    >
      <div class="tham-box">
        <div class="tham-inner bg-black" />
      </div>
    </div>
  </div>
  <div class=" flex justify-center items-center fixed z-10 top-0 left-0 w-screen h-screen bg-white bg-opacity-80 transition-opacity duration-500 " :class="isGlobalMenuOpen? ' opacity-100': ' pointer-events-none opacity-0'">
    <ul class=" text-center">
      <li class="mb-8">
        <a href="#" class="din text-4xl tracking-wider" v-scroll-to="'#concept'" @click="isGlobalMenuOpen=false">CONCEPT</a>
      </li>
      <li class="mb-8">
        <a href="#" class="din text-4xl tracking-wider" v-scroll-to="'#menu'" @click="isGlobalMenuOpen=false">MENU</a>
      </li>
      <li class="mb-8">
        <a href="#" class="din text-4xl tracking-wider" v-scroll-to="'#takeout'" @click="isGlobalMenuOpen=false">TAKE OUT</a>
      </li>
      <li class="mb-8">
        <a href="#" class="din text-4xl tracking-wider" v-scroll-to="'#information'" @click="isGlobalMenuOpen=false">INFORMATION</a>
      </li>
      <li class="mb-8">
        <a href="https://yoyaku.tabelog.com/yoyaku/net_booking_form/index?rcd=27011131" target="_blank" class="din text-4xl tracking-wider">RESERVATION</a>
      </li>
      <li>
        <a href="https://www.instagram.com/cloclo.1972/" target="_blank" class="din text-4xl tracking-wider">
          <svg class="d-inline-block mx-auto" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 448 512">
            <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
          </svg>

        </a>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      isGlobalMenuOpen: false
    }
  }
}
</script>

<style lang="scss">
.tham-w-8 .tham-box .tham-inner {
  &::before {
    top: -12px;
  }
  &::after {
    bottom: -12px;
  }
}
</style>